<template>
  <PageTop/>
  <!-- <PageLeft/>
  <PageRight/>
  <img alt="Vue logo" src="./assets/logo.png">
  <PinYinXingBuXing msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import PinYinXingBuXing from './components/HelloWorld.vue'
import PageTop from './components/top.vue'
// import PageLeft from './components/left.vue'
// import PageRight from './components/right.vue'
export default {
  name: 'App',
  components: {
    // PinYinXingBuXing,
	PageTop,
	// PageLeft,
	// PageRight
  }
}
</script>

<style>
#app2 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e77;
  margin-top: 10px;
}
</style>
