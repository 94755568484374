<template>
	<div id="container">
		<div id="top">顶部（top）简单网站标语 红头喜庆</div>
		<div id="left">左部（left）</div>
		<div id="mid">中部mid
			<!-- <div id="mid-top">中部上</div>
			<div id="mid-bottom">中部下</div>-->
		</div>
		<div id="right">右部支付宝扫码</div>
	</div>
</template>
<!-- <template>
  <div class="top">
    
  </div>
</template> -->
<script>
// export default {
//   name: 'PageTop',
//   props: {
//     msg: String
//   }
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 	.top{
		width: 100%;
		height: 100px;
		background-color: bisque;
	} */
	#container {
		width:1900%;
		height:100%;
		margin:0px auto;/*主面板DIV居中*/
		background-color: aliceblue;
	}
	/*顶部面板样式*/
	#top {
		width:100%;
		height:100px;
		float:left ;/*左边面板右浮动*/
		/* background-color: beige; */
	}
	/*左部面板样式*/
	#left {
		width:200px;
		height:800px;
		float:left ;/*左边面板右浮动*/
		/* background-color:yellow; */
	}
	/*中部面板样式*/
	#mid {
		width:1500px;
		height:800px;
		float:left;
		/* background-color:green; */
	}
	/*中部上面板样式*/
	/* #mid-top {
		width:100%;
		height:200px;
		background-color:#600;
	} */
	/*中部下面板样式*/
	/* #mid-bottom {
		width:100%;
		height:250px;
		background-color:#0FF;
	} */
	/*右边面板样式*/
	#right {
		width:200px;
		height:800px;
		float:left;
		/* background-color: bisque; */
	}
</style>
